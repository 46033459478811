import { useStaticQuery, graphql } from "gatsby"

export const useAlsQuery = () => {


    const data = useStaticQuery(graphql`
    query AlsQuery {
        wpPage(title: {eq: "ACCESSIBILITY AND LANGUAGE SERVICES"}) {
            ACF_ALSPage {
                alsHeader {
                    image{
                        publicUrl
                    }
                    title
                }
                alsContent {
                    content
                }

                alsMetaData {
                    metaTitle
                    metaDescription
                }
        }
        }
    }     
    `)
    return data.wpPage.ACF_ALSPage;
}