import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/accessibility-and-language-services/Hero";
import MainContent from "../components/accessibility-and-language-services/MainContent";
import { useAlsQuery } from "../hooks/useAlsQuery";
import Helmet from "react-helmet";

const AccessibilityAndLanguageServicesPage = () => {
    const data = useAlsQuery();
    return (
        <Layout>
            <Hero data={data.alsHeader} />
            <MainContent data={data.alsContent} />
            <Helmet>
                <title>{data.alsMetaData.metaTitle}</title>
                <meta name="description" content={data.alsMetaData.metaDescription} />
            </Helmet>
        </Layout>
    )
}

export default AccessibilityAndLanguageServicesPage;